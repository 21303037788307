import React from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'
import 'typeface-montserrat'
import 'typeface-merriweather'
import Scrollspy from 'react-scrollspy'
import styles from './navbar.module.scss';
import Headroom from "react-headroom"

const Logo = require('../../assets/images/minjLogo.png');


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const {alternate} = this.props;
        return (
            <Headroom
                style={{
                    zIndex: '100'
                }}
                upTolerance={20} disable={!!alternate}>
                <nav className={(alternate) ? 'navbar alternate' : 'navbar'}>
                    <div className="container">
                        <div className="navbar-brand" style={{padding: '0 3rem'}}>
                            <Link to="/" className="navbar-item">
                                <img style={{
                                    height: '60px',
                                    maxHeight: 'none'
                                }} className="is-marginless" src={Logo} alt="Logo | Travel Agency in Ranchi"/>
                            </Link>
                            <span className={((this.state.isOpen) ? "is-active" : "") + " navbar-burger burger"}
                                  data-target="navbarMenuHeroA" onClick={this.toggle}>
                                <span/>
                                <span/>
                                <span/>
                            </span>
                        </div>
                        <div id="navbarMenuHeroA" className={((this.state.isOpen) ? "is-active" : "") + " navbar-menu"}>
                            {/*<div className="navbar-end">*/}
                            <Scrollspy className="navbar-start" style={{flexGrow: 1, justifyContent: 'center'}}
                                       items={['about', 'contact']} currentClassName="is-current">
                                <Link to="/"
                                      className="navbar-item is-size-7 is-uppercase has-text-weight-semibold">Home</Link>
                                <a href="#about"
                                   className="navbar-item is-size-7 is-uppercase has-text-weight-semibold">About Us</a>
                                <div className="navbar-item has-dropdown is-hoverable is-mega">
                                    <a className="navbar-link is-size-7 is-uppercase has-text-weight-semibold">
                                        Packages
                                    </a>
                                    <div className="navbar-dropdown" style={{width: '100%'}}>
                                        <div className="">
                                            <StaticQuery
                                                query={graphql`
                                                  query {
                                                      honeymoon: allMarkdownRemark(filter: {frontmatter: {type: {eq: "honeymoon"}}}) {
                                                        edges {
                                                          node {
                                                            fields {
                                                              slug
                                                            }
                                                            frontmatter {
                                                              title
                                                              class
                                                            }
                                                          }
                                                        }
                                                      }
                                                      groupTours: allMarkdownRemark(filter: {frontmatter: {type: {eq: "tour"}}}) {
                                                        edges {
                                                          node {
                                                            fields {
                                                              slug
                                                            }
                                                            frontmatter {
                                                              title
                                                              class
                                                              date
                                                            }
                                                          }
                                                        }
                                                      }
                                                      pilgrimage: allMarkdownRemark(filter: {frontmatter: {type: {eq: "pilgrimage"}}}) {
                                                        edges {
                                                          node {
                                                            fields {
                                                              slug
                                                            }
                                                            frontmatter {
                                                              title
                                                              class
                                                              tour
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                `}
                                                render={data => (
                                                    <div className={styles.mega + " columns"}>
                                                        <div className="column is-half">

                                                            <Link to="packages/domestic"
                                                                  className="navbar-item has-margin-bottom-md">
                                                                <h2 className="title is-5 is-mega-menu-title"
                                                                    style={{color: '#1DA1F2'}}>
                                                                    Domestic
                                                                </h2>
                                                            </Link>
                                                            <div className="columns">
                                                                <div className="column">
                                                                    <Link to="domestic/honeymoon"
                                                                          className="navbar-item">
                                                                        <h3 className="title is-6">Honeymoon</h3>
                                                                    </Link>
                                                                    {
                                                                        data.honeymoon.edges.filter(itinerary => itinerary.node.frontmatter.class === 'domestic').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="column">
                                                                    <Link to="domestic/pilgrimage"
                                                                          className="navbar-item">
                                                                        <h2 className="title is-6">Pilgrimage Tours</h2>
                                                                    </Link>
                                                                    {
                                                                        data.pilgrimage.edges.filter(itinerary => itinerary.node.frontmatter.class === 'domestic').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                    <small
                                                                                        className="has-text-info">{itinerary.node.frontmatter.tour}</small>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className={styles.tours + " column"}>
                                                                    <a className="navbar-item">
                                                                        <h2 className="title is-6">Tours</h2>
                                                                    </a>
                                                                    {
                                                                        data.groupTours.edges.filter(itinerary => itinerary.node.frontmatter.class === 'domestic').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                    <small
                                                                                        className="has-text-info">{itinerary.node.frontmatter.date}</small>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="column is-half">
                                                            <Link to="packages/international"
                                                                  className="navbar-item has-margin-bottom-md">
                                                                <h2 className="title is-5 is-center is-mega-menu-title"
                                                                    style={{color: '#1DA1F2'}}>
                                                                    International
                                                                </h2>
                                                            </Link>
                                                            <div className="columns">
                                                                <div className="column">
                                                                    <Link to="international/honeymoon"
                                                                          className="navbar-item">
                                                                        <h2 className="title is-6">Honeymoon</h2>
                                                                    </Link>
                                                                    {
                                                                        data.honeymoon.edges.filter(itinerary => itinerary.node.frontmatter.class === 'international').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="column">
                                                                    <Link to="international/pilgrimage"
                                                                          className="navbar-item">
                                                                        <h2 className="title is-6">Pilgrimage Tours</h2>
                                                                    </Link>
                                                                    {
                                                                        data.pilgrimage.edges.filter(itinerary => itinerary.node.frontmatter.class === 'international').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                    <small
                                                                                        className="has-text-info">{itinerary.node.frontmatter.tour}</small>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="column">
                                                                    <a className="navbar-item">
                                                                        <h2 className="title is-6">Tours</h2>
                                                                    </a>
                                                                    {
                                                                        data.groupTours.edges.filter(itinerary => itinerary.node.frontmatter.class === 'international').map((itinerary, index) => (
                                                                            <Link key={index}
                                                                                  className={styles.iMenu + " navbar-item"}
                                                                                  to={itinerary.node.fields.slug}>
                                                                                <div className="navbar-content">
                                                                                    <p className="is-capitalize">{itinerary.node.frontmatter.title}</p>
                                                                                    <small
                                                                                        className="has-text-info">{itinerary.node.frontmatter.date}</small>
                                                                                </div>
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <hr className="navbar-divider"/>
                                        <div className="navbar-item">
                                            <div className="navbar-content">
                                                <h2 className="title is-6 is-mega-menu-title">Special Jharkhand</h2>
                                                <div className="level is-mobile">
                                                    <div className="level">
                                                        <div className="level-item">
                                                            <a className="navbar-item" href="#">
                                                                <div className="navbar-content">
                                                                    <p>Adventure Sports</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="level-item">
                                                            <a className="navbar-item" href="#">
                                                                <div className="navbar-content">
                                                                    <p>Weekend Getaways</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="level-item">
                                                            <a className="navbar-item" href="#">
                                                                <div className="navbar-content">
                                                                    <p>Tours</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div className="level-item">
                                                            <a className="navbar-item" href="#">
                                                                <div className="navbar-content">
                                                                    <p>Customized Trips</p>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to="gallery"
                                      className="navbar-item is-size-7 is-uppercase has-text-weight-semibold">Gallery</Link>
                                <a href="#contact"
                                   className="navbar-item is-size-7 is-uppercase has-text-weight-semibold">Contact</a>

                                {
                                    (!alternate) ? <div className="navbar-end is-marginless">
                                        <a href="https://www.facebook.com/Minj-Tours-Travels-110047763204485/" target="_blank" rel="noopener noreferrer" className="navbar-item icons">
                                    <span className="icon make-it-circle facebook-background">
                                      <i className="fab fa-facebook-f has-text-white"/>
                                    </span>
                                        </a>
                                        <a href="https://www.instagram.com/minjtourntravels/" target="_blank" rel="noopener noreferrer" className="navbar-item icons">
                                    <span className="icon make-it-circle facebook-background">
                                      <i className="fab fa-instagram has-text-white"/>
                                    </span>
                                        </a>
                                    </div> : null
                                }
                            </Scrollspy>
                            {/*</div>*/}
                        </div>
                    </div>
                </nav>
            </Headroom>
        );
    }
}

export default Navigation
