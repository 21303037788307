import React from 'react'
import '../../assets/index.scss'
import Navigation from '../Navbar/navbar'
import styles from './layout.module.scss'


class Template extends React.Component {
    render() {
        const {location, children, classes} = this.props

        return (
            <div className={styles.layout}>
                <Navigation alternate={true}/>
                {children}
            </div>
        )
    }
}

export default Template