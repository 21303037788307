import React from "react"
import Helmet from "react-helmet"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import _ from 'lodash'
import '../assets/index.scss'
import SecondaryLayout from '../components/Layout/secondaryLayout'
import styles from './category.module.scss';
import get from "lodash/get";
const aboutBg = require('../assets/images/aboutBg.jpg')

export default class Category extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const itineraries = get(
            this,
            'props.data.allMarkdownRemark.edges'
        )
        const type = get( this,'props.pageContext.type')
        const category = get( this,'props.pageContext.category')
        return (
            <SecondaryLayout location={this.props.location}>
                <Helmet title={`${_.capitalize(type)} ${_.capitalize(category)} Packages | Minj Tour & Travels`} />
                <section className={styles.category + " hero is-info is-fullheight"} style={{background: 'url(' + aboutBg + ') no-repeat center', backgroundSize:'cover'}}>
                    <div className="hero-body has-margin-top-xl">
                        <div className="container has-text-centered">
                            <h1 className="title is-2 has-color-accent">
                                {`${_.capitalize(type)} ${_.capitalize(category)} Packages`}
                            </h1>
                            <div className={styles.package + " tile is-ancestor is-multiline"}>
                                {
                                    itineraries.map((itinerary, index) => <div className="tile is-parent is-3" key={index}>
                                        <Link className="tile is-child notification is-info is-paddingless" to={itinerary.node.fields.slug}>
                                            <Img className="is-marginless" fluid={itinerary.node.frontmatter.feature.childImageSharp.fluid}
                                                 alt={itinerary.node.frontmatter.title}/>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    left: 0,
                                                    top: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    background: "rgba(0, 0, 0, 0.2)",
                                                    zIndex: 1,
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    flexDirection: 'column',
                                                    padding: '1rem',
                                                }}
                                            >
                                                <div className="content">
                                                    <p className="title is-size-4 is-uppercase">{itinerary.node.frontmatter.title}</p>
                                                    <p className="subtitle">{itinerary.node.frontmatter.cost}* | {itinerary.node.frontmatter.travel} Days </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="hero-footer">

                    </div>
                </section>
            </SecondaryLayout>
        )
    }
}

export const pageQuery = graphql`
  query CategoryByClass($type: String!, $category: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(filter: {frontmatter: {class:{eq: $type}, type: {eq: $category}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          class
          type
          feature {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          travel
          cost
          tour
        }
      }
    }
  }
  }
`